/* Container */
.home-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

/* Hero Section */
.hero-content {
  text-align: center;
  z-index: 1;
  margin-bottom: 4rem;
  max-width: 900px;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
  line-height: 1.2;
  transition: color 0.3s ease;
}

.hero-subtitle {
  font-size: 1.25rem;
  line-height: 1.6;
  transition: color 0.3s ease;
}

/* Icons Section */
.icons-container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.animated-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: float 2s ease-in-out infinite;
}

.icon-wrapper {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 1rem;
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 0.75rem;
}

.icon-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.icon {
  width: 3rem;
  height: 3rem;
  color: var(--icon-color);
  transition: transform 0.3s ease;
}

.icon-wrapper:hover .icon {
  transform: scale(1.1);
}

.icon-label {
  font-size: 0.875rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

/* Background Effects */
.background-effects {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
}

.gradient-orb {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  transition: all 0.3s ease;
  animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.orb-1 {
  top: -200px;
  right: -200px;
}

.orb-2 {
  bottom: -200px;
  left: -200px;
  animation-delay: 2s;
}

/* Animations */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle {
    font-size: 1.125rem;
  }
  
  .icons-container {
    gap: 1.5rem;
  }
  
  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}