/* General settings for fonts and layout */
html, body, #root, .app, .content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Banner styles */
.banner-text {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  font-size: 2rem; /* Default font size */
}

.banner-link {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .app {
    flex-direction: column; /* Stack elements vertically */
  }

  .banner-text {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .topbar {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px; /* Adjust spacing for mobile layout */
  }

  .navigation-links {
    flex-direction: column;
    gap: 8px; /* Stack navigation links vertically */
  }

  .icon-buttons {
    gap: 8px; /* Reduce spacing between icon buttons */
  }
}
